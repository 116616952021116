import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./header.css"

import Logo from "./logo"

const Header = ({ siteTitle }) => (
  <header>
    <div>
      <Link to="/">
        <Logo />
      </Link>

      <a className="email" href="mailto:&#099;&#097;&#114;&#111;&#108;&#121;&#110;&#064;&#111;&#114;&#097;&#110;&#103;&#101;&#100;&#111;&#103;&#046;&#099;&#111;&#046;&#122;&#097;">carolyn@orangedog.co.za</a>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
