/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="site">
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className="site-content">{children}</main>
      <footer>
        <section className="section section--footer h-card">
          <p>
            © {new Date().getFullYear()}&nbsp;
            <a className="p-name u-url" href="https://orangedog.co.za">Carolyn Archer</a>,&nbsp;
            <a className="u-email" href="mailto:&#099;&#097;&#114;&#111;&#108;&#121;&#110;&#064;&#111;&#114;&#097;&#110;&#103;&#101;&#100;&#111;&#103;&#046;&#099;&#111;&#046;&#122;&#097;">carolyn@orangedog.co.za</a>
          </p>
          <p>Proudly made in&nbsp;
            <span className="p-locality">Cape Town</span>,&nbsp;
            <span className="p-country-name">South Africa</span>
          </p>
        </section>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
